import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import ForceGraph3D from "react-force-graph-3d";
import SpriteText from "three-spritetext";
import "./FGraph.css";

const FGraph = ({ graphData, color, nodeColor, functions }) => {
  const fgRef = useRef();
  const [selectedNode, setSelectedNode] = useState(null);
  const [fromNode, setFromNode] = useState([]);
  const [toNode, setToNode] = useState([]);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const data = useMemo(() => {
    graphData.links.forEach((link) => {
      const a = graphData.nodes.filter((itm) => {
        return itm.id === link.source;
      })[0];
      const b = graphData.nodes.filter((itm) => itm.id === link.target)[0];
      a && !a.neighbors && (a.neighbors = []);
      b && !b.neighbors && (b.neighbors = []);
      const hasANeighbor = a && a.neighbors.find((aN) => a.id === aN.id);
      const hasBNeighbor = b && b.neighbors.find((bN) => b.id === bN.id);
      !hasANeighbor && a && b && a.neighbors.push(b);
      !hasBNeighbor && a && b && b.neighbors.push(a);

      a && !a.links && (a.links = []);
      b && !b.links && (b.links = []);
      const hasALink = a && a.links.find((aN) => a.id === aN.id);
      const hasBLink = b && b.links.find((bN) => b.id === bN.id);
      !hasALink && a && a.links.push(link);
      !hasBLink && b && b.links.push(link);
    });
    return graphData;
  }, [graphData]);

  useEffect(() => {
    if (selectedNode) {
      setFromNode([]);
      setToNode([]);
      selectedNode.links.forEach((link) => {
        if (link.target.id !== selectedNode.id) {
          setFromNode((oldArray) => [...oldArray, link.target.name]);
        } else {
          setToNode((oldArray) => [...oldArray, link.source.name]);
        }
      });
    }
  }, [selectedNode]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) => {
        highlightNodes.add(neighbor);
      });
      node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const formatNodeLayout = (node) => {
    if (highlightNodes.has(node) && node !== hoverNode) {
      const sprite = new SpriteText(`${node.title}`);
      sprite.textHeight = 4;
      sprite.color = color === "black" ? "#fff" : "#000";
      sprite.center.set(0.5, 2);
      return sprite;
    } else {
    }
  };

  const handleClick = useCallback(
    (node) => {
      const handleRender = (node) => {
        setTimeout(() => {
          highlightNodes.clear();
          highlightLinks.clear();
          if (node) {
            highlightNodes.add(node);
            node.neighbors.forEach((neighbor) => {
              highlightNodes.add(neighbor);
            });
            node.links.forEach((link) => highlightLinks.add(link));
          }
          setHoverNode(node || null);
          setSelectedNode(node);
        }, 100);
      };

      if (node && node !== data.nodes[0]) {
        const distance = 200;
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
        const nodes = fgRef.current;
        nodes.cameraPosition(
          {
            x: node.x * distRatio,
            y: node.y * distRatio,
            z: node.z * distRatio,
          },
          node,
          3000
        );
        // nodes.refresh();
        handleRender(node);
      }
    },
    [fgRef, data.nodes, highlightLinks, highlightNodes]
  );

  return (
    <div>
      <div className="fGraph">
        <div
          className="fGraphContainer"
          style={{
            border: "black",
            borderStyle: "double",
            borderWidth: "2px",
          }}
        >
          <ForceGraph3D
            ref={fgRef}
            graphData={data}
            nodeAutoColorBy={nodeColor}
            linkDirectionalParticleWidth={2}
            linkDirectionalParticles={(link) =>
              highlightLinks.has(link) ? 6 : 0
            }
            onNodeDragEnd={(node) => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
            }}
            nodeThreeObjectExtend={true}
            linkOpacity={0.45}
            linkWidth={(link) => (highlightLinks.has(link) ? 3 : 1)}
            onNodeClick={handleClick}
            onNodeHover={handleNodeHover}
            linkDirectionalParticleColor={(link) => link.source.color}
            backgroundColor={color}
            nodeThreeObject={formatNodeLayout}
            height={800}
            width={1920}
          />
        </div>
        {selectedNode && (
          <div className="graphCol">
            <div className="jobDetail">
              <div
                className={color === "black" ? "graphCard" : "graphCard_lite"}
              >
                <div
                  className={
                    color === "black"
                      ? "graphCardHeader"
                      : "graphCardHeader_lite"
                  }
                >
                  <p>Node Details</p>
                </div>
                <div
                  className={
                    color === "black" ? "graphCardBody" : "graphCardBody_lite"
                  }
                >
                  <p>Title: {selectedNode.title}</p>
                  <p>Sub Function: {selectedNode.subFunction}</p>
                  <p>Level: {selectedNode.level}</p>
                  <p>Sub Level: {selectedNode.subLevel}</p>
                </div>
              </div>
            </div>
            <div className="rDetail">
              <div
                className={color === "black" ? "graphCard" : "graphCard_lite"}
              >
                <div
                  className={
                    color === "black"
                      ? "graphCardHeader"
                      : "graphCardHeader_lite"
                  }
                >
                  <p>Related Nodes</p>
                </div>
                <div
                  className={
                    color === "black"
                      ? "graphCardHeader"
                      : "graphCardHeader_lite"
                  }
                >
                  <p>Source Nodes</p>
                </div>
                <div
                  className={
                    color === "black" ? "graphCardBody" : "graphCardBody_lite"
                  }
                >
                  {[...new Set(fromNode)].map((node, index) => {
                    return <p key={"fromnode-" + index}>{node}</p>;
                  })}
                  {fromNode.length === 0 && <p>No Source Nodes</p>}
                </div>
                <div
                  className={
                    color === "black"
                      ? "graphCardHeader"
                      : "graphCardHeader_lite"
                  }
                >
                  <p>Target Nodes</p>
                </div>
                <div
                  className={
                    color === "black" ? "graphCardBody" : "graphCardBody_lite"
                  }
                >
                  {[...new Set(toNode)].map((node, index) => {
                    return <p key={"tonode-" + index}>{node}</p>;
                  })}
                  {toNode.length === 0 && <p>No Target Nodes</p>}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FGraph;
